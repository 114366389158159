<template>
  <div class="flowEditor">
    <template v-if="laneConfig">
      <div class="row">
        <div class="col-12">
          <!-- VIDEO DIRECTOR CONFIG DEFAULT VISUALISER COMPONENT START -->
          <VideoDirectorConfigDefaultVisualiser
            ref="videoDirectorConfigDefaultVisualiser"
            :lane-config="laneConfig"
            :selected-trigger-id="trigger.id"
            :edit-trigger="true"
            :display-all-axes="true"
            :zoom-to-border="true"
            @changePtz="changePtz"
          />
          <!-- VIDEO DIRECTOR CONFIG DEFAULT VISUALISER COMPONENT END -->
        </div>
        <div class="col-12 pt-4">
          <ul class="nav nav-tabs">
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              class="nav-item"
            >
              <a
                :class="['nav-link', activeTab === tab ? 'active' : '']"
                @click="changeTab(tab)"
              >{{ $t(`triggerEditor.tab.${ tab }`) }}</a>
            </li>
          </ul>

          <div v-if="activeTab === 'common'">
            <h5>Common</h5>

            <label>{{ $t('name') }}</label>
            <input
              v-model="trigger.name"
              class="form-control"
              type="text"
            >
            <label>Delay</label>
            <input
              v-model="trigger.delay"
              type="number"
              class="form-control"
              min="0"
              max="2000"
              step="1"
            >
            <label>Type</label>
            <select
              v-model="trigger.type"
              class="form-control rounded cursor-pointer"
            >
              <option
                v-for="(type, typeIndex) in triggerTypes"
                :key="`triggerType-${ typeIndex }`"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
          <div v-if="activeTab === 'area'">
            <h5>Area</h5>
            <table
              v-if="trigger.area.length"
              class="table kendo-table table-kendo"
            >
              <colgroup>
                <col width="35">
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{{ $t('pan') }}</th>
                  <th>{{ $t('tilt') }}</th>
                  <th>{{ $t('option') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in trigger.area"
                  :key="`tableRow-${ index }`"
                >
                  <td>{{ index }}</td>
                  <td>
                    <input
                      v-model.number="item.pan"
                      type="number"
                      class="form-control form-control-sm"
                      min="-170"
                      max="170"
                      step="1"
                      @keyup="item.pan = clamp(item.pan.toFixed(2), -170, 170)"
                    >
                  </td>
                  <td>
                    <input
                      v-model.number="item.tilt"
                      type="number"
                      class="form-control form-control-sm"
                      min="-80"
                      max="80"
                      step="1"
                      @keyup="item.tilt = clamp(item.tilt.toFixed(2), -80, 80)"
                    >
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="removeItem(index)"
                    >
                      {{ $t('remove') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr v-else>
            <div class="text-right mt-2">
              <button
                class="btn btn-sm btn-primary text-right"
                @click="addItem()"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="plus"
                />
                <span>{{ $t('add') }}</span>
              </button>
            </div>
          </div>

          <hr>
          
          <div class="text-right">
            <button
              class="btn btn-sm btn-primary"
              @click="close()"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TriggerEditor',
  components: {
    VideoDirectorConfigDefaultVisualiser: () => import('../Visualiser/VideoDirectorConfigDefaultVisualiser.vue'),
  },
  props: {
    trigger: {
      type: Object,
      required: true
    },
    laneConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      triggerTypes: [ 'VirtualStopwatch', 'PlaySound' ],
      tabs: [ 'common', 'area' ],
      activeTab: 'common',
    }
  },
  methods: {
    clamp (num, min, max) {
      return num <= min ? min : num >= max ? max : num;
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    close () {
      this.$emit('close');
    },
    changePtz (position) {
      this.$emit('changePtz', position);
    },
    changeTab (tabName) {
      this.activeTab = tabName;
    },
    addDefaultArea () {
      this.trigger.area.push({ pan: -10, tilt: 10 });
      this.trigger.area.push({ pan: 10, tilt: 10 });
      this.trigger.area.push({ pan: 10, tilt: -10 });
      this.trigger.area.push({ pan: -10, tilt: -10 });
      this.update();
    },
    removeItem (removeIndex) {
      this.trigger.area.splice(removeIndex, 1);
      this.update();
    },
    addItem () {
      if (!this.trigger.area.length) {
        this.addDefaultArea();
        return;
      }

      let pan = 0;
      let tilt = 0;

      if (this.trigger.area.length >= 2) {
        let firstPosition = this.trigger.area[0];
        let lastPosition = this.trigger.area[this.trigger.area.length - 1];
        pan = this.round((firstPosition.pan + lastPosition.pan) / 2);
        tilt = this.round((firstPosition.tilt + lastPosition.tilt) / 2);
      }

      this.trigger.area.push({ pan: pan, tilt: tilt });
      this.update();
    },
    update () {
      this.$refs.videoDirectorConfigDefaultVisualiser.update();
    },
  }
}
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}
</style>